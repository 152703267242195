// create new account
//const account = tronWeb.createAccount();

//IMPORTANT: don't forget to encrypt account private key before store
//and don't forget clear variable[memory!] 
//account result example::
//{ address: {
//  base58: "TPbBpRXnt6ztse8XkCLiJstZyqQZvxW2sx", 
//hex: "4195679F3AAF5211991781D49B30525DDDFE9A18DE"}
//privateKey: "08089C24EC3BAEB34254DDF5297CF8FBB8E031496FF67B4EFACA738FF9EBD455"
//publicKey:  "04EE63599802B5D31A29C95CC7DF04F427E8F0A124BED9333F3A80404ACFC3127659C540D0162DEDB81AC5F74B2DEB4962656EFE112B252E54AC3BA1207CD1FB10" }
import React,{ useState, useRef } from "react";
import TronWeb from "../tronweb/src/index.js";
import Axios from "axios";
import styled from "styled-components";
import { decrypt, encrypt } from "../components/main/Main.js";
import { toast,ToastContainer } from "react-toastify";
import { Buffer } from "buffer";
import Cookies from "js-cookie";
import dictionary from "../data/words_dictionary.json";
import Header from "../components/header/Header.js";
import { useNavigate  } from "react-router-dom";

export default function NewAccount(props){
    const navigate = useNavigate();
    const [privateKey,setPrivateKey] = useState("");
    const [privateText,setPrivateText] = useState("palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1 palabra1");
    const [wallet,setWallet] = useState("");
    const [showWallet,setShowWallet] = useState(false);
    const HttpProvider = TronWeb.providers.HttpProvider;
    const fullNode = new HttpProvider('https://nile.trongrid.io');
    const solidityNode = new HttpProvider('https://nile.trongrid.io');
    const eventServer = new HttpProvider('https://nile.trongrid.io');
    const toastId = useRef();
    const [copy,setCopy] = useState(false);
    const [copy2,setCopy2] = useState(false);
    const [copy3,setCopy3] = useState(false);
    
    const notify = ()=>toastId.current = toast("Wait a moment...",{closeOnClick:true,autoClose:5000});
    const update = (message,type)=>toast.update(toastId.current, {render:message,type: type,closeOnClick:true,autoClose:5000});

    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
      
    const randomBytes = ()=>{
        var rString = randomString(4, '0123456789abcdefghijklmnopqrstuvwxyz');
        const buf1 = Buffer.from(rString, 'hex');
        return buf1;
    }

    const randomWords = (length) => {
        var result = '';
        for (var i = length; i > 0; --i) result += dictionary[Math.floor(Math.random() * dictionary.length)]+" ";
        return result;
    }


    const createWallet = async () =>{
        notify();
        let privateK = randomWords(12);
        console.log(privateK);
        setPrivateText(privateK);
        let session = Cookies.get("session");
        let id = localStorage.getItem("user_id");
        let mail = localStorage.getItem("email");
        const tronWeb = new TronWeb(fullNode,solidityNode,eventServer,privateKey);
        const account = tronWeb.createAccount();
        account.then(async (resp)=>{
            let privateKey = resp.privateKey;
            let wallet = resp.address.base58;
            console.log(JSON.stringify(resp));
            let params = new FormData();
            params.append("id", id);
            params.append("address", JSON.stringify(resp));

            const user = { user_id:id, email: mail, name: "JohnDoe", address:JSON.stringify(resp)};

            // Create the session
            const expires = new Date(Date.now() + 24*60*60 * 1000 - new Date().getTimezoneOffset()*60*1000);
            session = await encrypt({ user, expires });
            let form = {"id":id,"address":JSON.stringify(resp)};
            // Save the session in a cookie
            Cookies.set("session", session, { expires, httpOnly: false, secure: true, path: '/', sameSite: 'none' });
            try{
                Axios.post("https://rubywallet.aylemrm.com/server/createWallet.php",
                    {data:form}
                ).then((resp)=>{
                    let message = resp.data.message;
                    let status = resp.data.status;
                    if(status==="error"){
                        update(message,"error");
                    }
                    if(status==="success"){
                        update(message,"success");
                        setWallet(wallet);
                        setPrivateKey(privateKey);
                        setShowWallet(true);

                    }
                }).catch((e)=>{
                    console.log("Error axios 99: ",e)
                });
            }catch (error) {
                // Handle error if necessary
                console.error(error)
            }
        });
    }

    const gotoDashboard = ()=>{
        navigate("/dashboard");
    }
    const copyWallet = (data,type)=>{
        navigator.clipboard.writeText(data);
        switch(type){
            case 1:
                setCopy(true);
                setTimeout(()=>setCopy(false),5000);
                break;
            case 2:
                setCopy2(true);
                setTimeout(()=>setCopy2(false),5000);
                break;
            case 3:
                setCopy3(true);
                setTimeout(()=>setCopy3(false),5000);
                break;
        }
    }
    return (
        <>
            <Header/>
            <div className="main" style={{backgroundColor:"white",borderRadius:5}}>
                <div className="content">
                    <Content>
                        <ToastContainer/>
                        <div style={{fontWeight:"bold"}}>{showWallet?"Wallet created successfully":"You need to create your wallet first"}</div>
                        <div><input type="button" onClick={showWallet?gotoDashboard:createWallet} value={showWallet?"Go to Dashboard":"Create Wallet"} className="caseinput" /></div>
                        {showWallet &&
                            <div>
                                <div style={{fontWeight:"bold"}}>Wallet</div>
                                <div>{wallet} {copy?
                                <img src="/images/check.png" alt="copy" width={20} height={20} style={{filter: "brightness(0)"}}/>
                                :
                                <img title="Copy wallet" src="/images/copy.png" alt="copy" width={20} height={20} 
                                    style={{cursor:"pointer",filter: "brightness(0)"}}
                                    onClick={()=>{copyWallet(wallet,1)}}/>
                                }</div>
                                <div style={{fontWeight:"bold",paddingTop:20}}>Random Text</div>
                                <div>
                                <div style={{display:"grid",gridTemplateColumns:"auto auto auto",gap:10}}>
                                    {
                                        privateText.trim().split(" ").map((item,index)=>{
                                            return <div key={index} 
                                                    style={{backgroundColor:"#179bd7",borderRadius:5,
                                                        padding: 10,color: "white",
                                                        fontWeight: "bold",fontSize: "1.2rem"
                                                    }}>{item}</div>})
                                    }</div> {copy2?
                                <img src="/images/check.png" alt="copy" width={20} height={20} style={{filter: "brightness(0)",paddingTop:20}}/>
                                :
                                <img title="Copy private text" src="/images/copy.png" alt="copy" width={20} height={20} 
                                    style={{cursor:"pointer",filter: "brightness(0)",paddingTop:20}}
                                    onClick={()=>{copyWallet(privateText,2)}}/>
                                }</div>
                                <div style={{fontWeight:"bold",paddingTop:20}}>Your Private Key</div>
                                <div>{privateKey} {copy3?
                                <img src="/images/check.png" alt="copy" width={20} height={20} style={{filter: "brightness(0)"}}/>
                                :
                                <img title="Copy private key" src="/images/copy.png" alt="copy" width={20} height={20} 
                                    style={{cursor:"pointer",filter: "brightness(0)"}}
                                    onClick={()=>{copyWallet(privateKey,3)}}/>
                                }</div>
                            </div>
                        }
                    </Content>
                </div>
            </div>
        </>
    )
}
const Content = styled.div`
  background-color:white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width:100%;
  border-radius:5px;
  padding: 10px`;
const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  padding: 20px 0;`;