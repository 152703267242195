import React,{useEffect,useState} from "react";
import "./styles.css";

import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { WalletContext, WalletMarginContext } from "./providers/providers";

export default function App() {
  const [context, setContext] = useState(WalletContext);
  const navigate = useNavigate();
  useEffect(()=>{
    if(typeof Cookies.get("session") === "undefined" ){
        navigate("/");
    };
  },[]);
  return (
    <WalletContext.Provider value={[context, setContext]}>
      <WalletMarginContext.Provider value={WalletMarginContext}>
        <div className="App">
          <Main message="Total Balance" />
          <Footer note="Footer Note" />
        </div>
      </WalletMarginContext.Provider>
    </WalletContext.Provider>
  );
}
