import React from "react";
import "./Main.css";
import "./Login.css";
import "../../styles.css";
import Axios from "axios";
import { useNavigate  } from "react-router-dom";
import { useRef, useState } from "react";
import Cookies from "js-cookie";
import { SignJWT, jwtVerify } from "jose";

const secretKey = "secret";
const key = new TextEncoder().encode(secretKey);

export async function encrypt(payload) {
  return await new SignJWT(payload)
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime("24 hours from now")
    .sign(key);
}

export async function decrypt(input) {
    const { payload } = await jwtVerify(input, key, {
      algorithms: ["HS256"],
    });
    return payload;
}

export async function getAddress(){
  let session = Cookies.get("session");
  let data = await decrypt(sessionf);
  return JSON.parse(data.user.address);
}

export async function getId(){
  let session = Cookies.get("session");
  let data = await decrypt(session);
  return JSON.parse(data.user.id);
}

export default function Login(props){
  const [isLoading, setIsLoading] = useState(false);
  const emailInput = useRef();
  const passwordInput = useRef();
  const navigate = useNavigate();

  async function onSubmit(event) {
    let mail = emailInput.current?.value;
    let pwd = passwordInput.current?.value;
    event.preventDefault();
    setIsLoading(true);
    try {
      "use server";
    
      let form = {"username":mail,"password":pwd};
      await Axios.post("https://rubywallet.aylemrm.com/server/users.php",
        {data:form},
      ).then(async(resp)=>{
        if (resp.data.status==="error"){
          console.log(resp.data.message,"error");
        }else if(resp.data.status==="success"){
          // Verify credentials && get the user
          let user_id = resp.data.id;
          localStorage.setItem("user_id",user_id);
          localStorage.setItem("email",mail);
          const user = { user_id: user_id, email: mail, name: "JohnDoe", address:""};

          // Create the session
          const expires = new Date(Date.now() + 24*60*60 * 1000 - new Date().getTimezoneOffset()*60*1000);
          const session = await encrypt({ user, expires });

          // Save the session in a cookie
          Cookies.set("session", session, { expires, httpOnly: false, secure: true, path: '/', sameSite: 'none' });

          navigate("/dashboard");
        }else if(resp.data.status==="create"){
          // Verify credentials && get the user
          let user_id = resp.data.id;
          localStorage.setItem("user_id",user_id);
          localStorage.setItem("email",mail);
          const user = { user_id: user_id, email: mail, name: "JohnDoe", address:""};

          // Create the session
          const expires = new Date(Date.now() + 24*60*60 * 1000 - new Date().getTimezoneOffset()*60*1000);
          const session = await encrypt({ user, expires });

          // Save the session in a cookie
          Cookies.set("session", session, { expires, httpOnly: false, secure: true, path: '/', sameSite: 'none' });

          navigate("/newAccount");
        }
      }).catch((e)=>{
        console.log("Axios error",e)
      })
    } catch (error) {
      // Handle error if necessary
      console.error(error)
    } finally {
      setIsLoading(false) // Set loading to false when the request completes
    }
  }
      return <div className="App">
                  <div className="center">
                      <div className="figureCSS">
                          <div>
                              <img alt="logo" src="/images/logo_min.png" style={{height:80,width:"auto"}}/>
                          </div>
                      </div>
                      <div className="login">
                        <form style={{display:"flex",
                                    flexDirection:"column",
                                    padding:20,
                                    gap: 10,
                                    justifyContent:"center",
                                    alignItems: "center"}}
                                    onSubmit={onSubmit}
                          >
                          <div className="case">
                              <img alt="user" src="/images/user.png" width={25} height={25}/>
                              <input ref={emailInput} type="email" name="email" className="caseinput" placeholder="Email" required/>
                          </div>
                          <div className="case">
                              <img alt="user" src="/images/password.png" width={25} height={25}/>
                              <input ref={passwordInput} type="password" name="password" className="caseinput" placeholder="Password" required/>
                          </div>
                              <input type="submit" disabled={isLoading} value={isLoading ? 'Loading...' : 'LOGIN'}/>
                          </form>
                          <a href="/signup" style={{color:"white",textDecoration:"none"}} >Sign Up</a>
                      </div>
                  </div>
                </div>
}