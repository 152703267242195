import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Dashboard from "./pages/dashboard";
import Analytics from "./pages/analytics";
import Configuration from "./pages/configuration";
import Notifications from "./pages/notifications";
import Transfer from "./pages/transfer";
import SignUp from "./pages/signup";
import NewAccount from "./pages/newAccount";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="/analytics" element={<Analytics/>}/>
        <Route path="/configuration" element={<Configuration/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/notifications" element={<Notifications/>}/>
        <Route path="/transfer" element={<Transfer/>}/>
        <Route path="/newAccount" element={<NewAccount/>}/>
      </Routes>
    </Router>
  </React.StrictMode>
);