import React, { useState } from "react";
import "./Header.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Header(props) {
  const [state,setState] = useState(false);
  const navigate = useNavigate();
  const logout= ()=>{
    Cookies.remove("session");
    navigate("/");
  }
  return (
    <>
    {props.pathname !=="/" && props.pathname !=="/signup" &&
    <div className="menu" style={{cursor:"pointer"}} onClick={()=>setState(!state)}>
      <img alt="menu" src="/images/menu.png"/>
      {state && 
      <Menu>
        <Item className="selector"><a href="/dashboard" style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img style={{width:25,filter:"grayscale(1)"}} alt="wallet" src="/images/wallet.svg" width={25} height={25}/>Inicio</a></Item>
        <Item className="selector"><a href="/analytics" style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img style={{width:25,filter:"grayscale(1)"}} alt="analytics" src="/images/analytics.svg" width={25} height={25}/>Analytics</a></Item>
        <Item className="selector"><a href="/transfer" style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img style={{width:25,filter:"grayscale(1)"}} alt="transfer" src="/images/exchange.svg" width={25} height={25}/>Transfer</a></Item>
        <Item className="selector"><a href="/notifications" style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img style={{width:25,filter:"grayscale(1)"}} alt="notifications" src="/images/notifications.svg" width={25} height={25}/>Notifications</a></Item>
        <Item className="selector"><a href="/configuration" style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img style={{width:25,filter:"grayscale(1)"}} alt="configuration" src="/images/settings.svg" width={25} height={25}/>Settings</a></Item>
        <Item className="selector"><div onClick={logout} style={{textDecoration:"none",
            gap:10,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"left",
            fontWeight:"bold",
            fontSize:"1.2rem",
            width:"95%",padding:"5px 5px",borderRadius:5}}><img  style={{width:25,filter:"grayscale(1)"}} alt="wallet" src="/images/logout.svg" width={25} height={25}/>Logout</div></Item>
      </Menu>
    }
    </div>
    }
    <div className="header">
      <img alt="logo" src="/images/logo.png"/>
    </div>
    </>
  );
}
const Menu = styled.div`
  width: 200px;
  right: 0px;
  position: absolute;
  background-color: white;
  border: 2px solid #ae1010;
  border-radius: 10px 5px 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`;
const Item = styled.div`
  width: 100%;
  color: #343434;
  :hover{
    background-color: #e9b1b161;
  }
`;