import React,{ useEffect, useLayoutEffect, useState } from "react";
import "./Footer.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function Footer(props) {
  const navigate = useNavigate();
  useEffect(()=>{
    if(typeof Cookies.get("session") === "undefined" ){
      navigate("/");
        return;
    }
  },[]);

  const logout= ()=>{
    Cookies.remove("session");
    navigate("/");
  }

  return (<div className="footer" >
        {props.link==="inicio" &&
      <>
      <div className="selector"><img alt="logout" onClick={logout} src="/images/logout.svg" width={45} height={45}/></div>
      <div className="selector"><a href="/analytics"><img alt="analytics" src="/images/analytics.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/transfer"><img alt="transfer" src="/images/exchange.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/notifications"><img alt="notifications" src="/images/notifications.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/configuration"><img alt="configuration" src="/images/settings.svg" width={45} height={45}/></a></div>
      </>
      }
      {props.link==="analytics" && 
      <>
      <div className="selector"><img alt="logout" onClick={logout} src="/images/logout.svg" width={45} height={45}/></div>
      <div className="selector"><a href="/dashboard"><img alt="wallet" src="/images/wallet.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/transfer"><img alt="transfer" src="/images/exchange.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/notifications"><img alt="notifications" src="/images/notifications.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/configuration"><img alt="configuration" src="/images/settings.svg" width={45} height={45}/></a></div>
      </>
      }
      {
      props.link==="login" && 
      <>
      <div className="selector"><a href="/dashboard"><img alt="wallet" src="/images/wallet.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/analytics"><img alt="analytics" src="/images/analytics.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/transfer"><img alt="transfer" src="/images/exchange.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/notifications"><img alt="notifications" src="/images/notifications.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/configuration"><img alt="configuration" src="/images/settings.svg" width={45} height={45}/></a></div>
      </>
      }
      {
      props.link==="transfer" && 
      <>
      <div className="selector"><img alt="logout" onClick={logout} src="/images/logout.svg" width={45} height={45}/></div>
      <div className="selector"><a href="/dashboard"><img alt="wallet" src="/images/wallet.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/analytics"><img alt="analytics" src="/images/analytics.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/notifications"><img alt="notifications" src="/images/notifications.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/configuration"><img alt="configuration" src="/images/settings.svg" width={45} height={45}/></a></div>
      </>
      }
      {props.link==="notifications" && 
      <>
      <div className="selector"><img alt="logout" onClick={logout} src="/images/logout.svg" width={45} height={45}/></div>
      <div className="selector"><a href="/dashboard"><img alt="wallet" src="/images/wallet.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/analytics"><img alt="analytics" src="/images/analytics.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/transfer"><img alt="transfer" src="/images/exchange.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/configuration"><img alt="configuration" src="/images/settings.svg" width={45} height={45}/></a></div>
      </>
      }
      {props.link==="configuration" && 
      <>
      <div className="selector"><img alt="logout" onClick={logout} src="/images/logout.svg" width={45} height={45}/></div>
      <div className="selector"><a href="/dashboard"><img alt="wallet" src="/images/wallet.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/analytics"><img alt="analytics" src="/images/analytics.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/transfer"><img alt="transfer" src="/images/exchange.svg" width={45} height={45}/></a></div>
      <div className="selector"><a href="/notifications"><img alt="notifications" src="/images/notifications.svg" width={45} height={45}/></a></div>
      </>
      }
      </div>
  )
}
