import { useRef, useState } from 'react';
import Axios from 'axios';
import "../styles.css";
import "../components/main/Login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function SignUp(props) {
  const usernameInput = useRef();
  const passwordInput = useRef();
  const [status,setStatus] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef();

  const notify = ()=>toastId.current = toast("Wait a moment...",{closeOnClick:true,autoClose:5000});
  const update = (message,type)=>toast.update(toastId.current, {render:message,type: type,closeOnClick:true,autoClose:5000});
  const handleSignUp = (e) => {
    setStatus(true);
    notify();
    let username = usernameInput.current?.value;
    let password = passwordInput.current?.value;
    let params = new FormData();
    params.append("username", username);
    params.append("password", password);
    try {
      Axios.post('https://rubywallet.aylemrm.com/server/signup.php',
        params
      ).then((resp)=>{
        if (resp.data.status==="error"){
          update(resp.data.message,"error");
        }else if(resp.data.status==="success"){
          update(resp.data.message,"success");
          setStatus(false);
          navigate('/');
        }else{
          update("Connection error","error");
        }
        if(resp.status===500){
          update("500 Internal Server Error","error");
        }
        setStatus(false);
      }).catch((e)=>{
        setStatus(false);
        console.log(e);
      })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <ToastContainer/>
    <div className="App">
    <div className="center">
        <div className="figureCSS">
            <div>
                <img alt="logo" src="/images/logo_min.png" style={{height:80,width:"auto"}}/>
            </div>
        </div>
        <div className="login">
            <div className="case">
                <img alt="user" src="/images/user.png" width={25} height={25}/>
                <input ref={usernameInput} type="email" className="caseinput" 
                  onChange={(e)=>{e.currentTarget.style.backgroundColor="white"}}
                  required placeholder="Email"/>
            </div>
            <div className="case">
                <img alt="user" src="/images/password.png" width={25} height={25}/>
                <input ref={passwordInput} type="password" className="caseinput" 
                  onChange={(e)=>{e.currentTarget.style.backgroundColor="white"}}
                  required placeholder="Password"/>
            </div>
            {status?
            <input type="button" style={{cursor:"pointer"}} value={"Loading..."}/>
            :
            <input type="button" style={{cursor:"pointer"}} onClick={handleSignUp} value={"Sign Up"}/>
            }
            <a href="/" style={{color:"white",textDecoration:"none"}}>Login</a>
        </div>    
      </div>
    </div>
  </>
  )
}