import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import Axios from "axios";
import TronWeb from "../tronweb/src/index.js";
import Footer from "../components/footer/Footer.js";
import Cookies from "js-cookie";
import Header from "../components/header/Header.js";
import { encrypt, decrypt } from "../components/main/Main.js";
import { WalletContext,WalletMarginContext } from "../providers/providers.js";
import { useNavigate } from 'react-router-dom';

export default function Dashboard(props) {
    const [content,setContent] = useState(false);
    const [createWallet,setCreateWallet] = useState(true);
    const [context, setContext] = useContext(WalletContext);
    const [wallet,setWallet] = useState("");
    const [privateKey, setPrivateKey] = useState(""); 
    const navigate = useNavigate();
    useLayoutEffect(()=>{
        if(typeof Cookies.get("session") === "undefined" ){
            setContent(false);
        }else{
            setContent(true);
        }
    },[]);

    useEffect(()=>{
        checkWallet();
        //getBalance();
    },[]);

    useEffect(()=>{
        if(!createWallet){
            getBalance();
        }
    },[createWallet]);

    const [balance,setBalance] = useState([
        {name:"TRX",value:"0",contract:"",sun:1000000,price:0},
        {name:"JST",value:"0",contract:"TF17BgPaZYbz8oxbjhriubPDsA7ArKoLX3",sun:Math.pow(10,15)*1000,price:0},
        {name:"USDT",value:"0",contract:"TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf",sun:1,price:0}]);

    //const privateKey = "694FD27F32A1BDE23526CB0CA9994572B4FB74FD21E5DE2EF5F53D636869FE44";//Crypto.randomBytes(32).toString('hex');
    const HttpProvider = TronWeb.providers.HttpProvider;
    const ACCOUNT = "TWcyY8MicY63E1Qyc5NNiSWRv6n3JzRycw";
    //const fullNode = new HttpProvider("https://api.trongrid.io");
    //const solidityNode = new HttpProvider("https://api.trongrid.io");
    //const eventServer = new HttpProvider("https://api.trongrid.io");
    //const fullNode = new HttpProvider('https://api.shasta.trongrid.io');
    //const solidityNode = new HttpProvider('https://api.shasta.trongrid.io');
    //const eventServer = new HttpProvider('https://api.shasta.trongrid.io');
    const fullNode = new HttpProvider('https://nile.trongrid.io');
    const solidityNode = new HttpProvider('https://nile.trongrid.io');
    const eventServer = new HttpProvider('https://nile.trongrid.io');
    const tronWeb = new TronWeb(fullNode,solidityNode,eventServer,privateKey);
    //tronWeb.setHeader({ "TRON-PRO-API-KEY": '43569e1e-3a84-48bd-93d2-99c71d87e758' });
    /**
     * CONTRACTS
     */
    const JST = "TF17BgPaZYbz8oxbjhriubPDsA7ArKoLX3";//JST CONTRACT
    const USDT = "TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf";//USDT nile

    const checkWallet = async ()=>{
        let session = Cookies.get("session");
        let data = await decrypt(session);
        let params = new FormData();
        let username = data.user.email;
        params.append("username", username);
        Axios.post("https://rubywallet.aylemrm.com/server/checkWallet.php",
            params
        )
        .then(async (resp)=>{
            if(resp.data.status==="Create"){
                setCreateWallet(true);
                navigate("/newAccount");
            }else if(resp.data.status==="success"){
                const user = { email: username, name: "JohnDoe", address:JSON.parse(resp.data.address) };
                // Create the session
                const expires = new Date(Date.now() + 24*60*60 * 1000 - new Date().getTimezoneOffset()*60*1000);
                const session = await encrypt({ user, expires });
                //console.log(resp.data)
                // Save the session in a cookie
                Cookies.set("session", session, { expires, httpOnly: false, secure: true, path: '/', sameSite: 'none' });
                let address = JSON.parse(resp.data.address);
                //setContext(address.address.base58);
                localStorage.setItem("wallet",address.address.base58);
                setWallet(address.address.base58);
                setPrivateKey(address.privateKey)
                setCreateWallet(false);
            }
        }).catch((e)=>{
            console.log(e);
        })
    }

    const getPrices = ()=>{
        let urls = [
            "https://loaneth.aylemrm.com/services/index.php?pair=trx",
            "https://loaneth.aylemrm.com/services/index.php?pair=jst",
            "https://loaneth.aylemrm.com/services/index.php?pair=usdt",
        ];
        const requests = urls.map((url) => Axios.get(url));
        Axios.all(requests).then(
            Axios.spread((resp0, resp1, resp2) => {    
                let newBalance = balance.map((item,index)=>{
                    switch(index){
                        case 0:
                            return {...item,price:parseFloat(resp0.data.price)};
                        case 1:
                            return {...item,price:parseFloat(resp1.data.price)};
                        case 2:
                            return {...item,price:parseFloat(resp2.data.price)};
                    }
                })
                setBalance(newBalance);
            }));  
    }

    const getBalance=()=>{
        Axios.post("https://nile.trongrid.io/wallet/getaccount",
            {"address": wallet,
            "visible": true}
        ).then((resp)=>{
            balance[0].value = (isNaN(resp.data.balance)?0:resp.data.balance/1000000).toString()
            let newBalance = balance.map((item,index)=>{
                if(index===0){
                    return {...item,value:(resp.data.balance/1000000).toString()};
                }else{
                    return item;
                }
            })
            setBalance(newBalance);
            getBalanceTokens();
        }).catch((e)=>{
            console.log("Error getting balance from tron network",e);
        });
    }

    const getBalanceTokens = async ()=>{
        balance.map(async (item,index)=>{
            if(index>0){
                const {
                    abi
                } = await tronWeb.trx.getContract(item.contract);
            
                const contract = tronWeb.contract(abi.entrys, item.contract);
            
                let blnc = await contract.methods.balanceOf(wallet).call();
                item.value = (parseInt(blnc._hex)/item.sun).toString();
            }
        })
        getPrices();
    }
    return <>{content ? <>
                <Header/>
                <div className="main">
                    <div className="content">
                        <div className="balance">
                            <h2>{props.message}</h2>
                            <h1>${balance[0].value*balance[0].price+balance[1].value*balance[1].price+balance[2].value*balance[2].price}</h1>
                            <p>+49,89%</p>
                        </div>
                        <div className="wallet">
                            <div className="token"><img src="/images/TRX.png" alt={balance[0].name} width={45} height={45}/></div>
                            <div className="wallet_value">
                            <h2 className="wallet_total">{balance[0].value}</h2>
                            <p className="wallet_balance">${balance[0].price}</p>
                            </div>
                            <div className="wallet_value">
                            <h2 className="wallet_price">${balance[0].value*balance[0].price}</h2>
                            <p className="wallet_change" style={{color:"#00bf63"}}>+4,89%</p>
                            </div>
                        </div>
                        <div className="wallet">
                        <div className="token"><img src="/images/JST.png" alt={balance[1].name} width={45} height={45}/></div>
                            <div className="wallet_value">
                            <h2 className="wallet_total">{balance[1].value}</h2>
                            <p className="wallet_balance">${balance[1].price}</p>
                            </div>
                            <div className="wallet_value">
                            <h2 className="wallet_price">${balance[1].value*balance[1].price}</h2>
                            <p className="wallet_change" style={{color:"#00bf63"}}>+54,23%</p>
                            </div>
                        </div>
                        <div className="wallet">
                        <div className="token"><img src="/images/USDT.png" alt={balance[2].name} width={45} height={45}/></div>
                            <div className="wallet_value">
                            <h2 className="wallet_total">{balance[2].value}</h2>
                            <p className="wallet_balance">${balance[2].price}</p>
                            </div>
                            <div className="wallet_value">
                            <h2 className="wallet_price">${balance[2].value*balance[2].price}</h2>
                            <p className="wallet_change" style={{color:"red"}}>-5,93%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
           :<>Not Logged in, return to login</>
            }
                <Footer link="inicio"/>
           </>
}
