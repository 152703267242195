import React,{useLayoutEffect,useState} from "react";
import Footer from "../components/footer/Footer";
import Cookies from "js-cookie";
import Header from "../components/header/Header";
import styled from "styled-components";

export default function Analytics(props){
    const [content,setContent] = useState(false);
    useLayoutEffect(()=>{
        if(typeof Cookies.get("session") === "undefined" ){
            setContent(false);
        }else{
            setContent(true);
        }
    },[]);
    return <>
            {content ?
                <>
                    <Header/>
                    <Content style={{height:"calc(100vh - 192px)"}}>
                        <div>Analytics</div>
                    </Content>
                </>
                :<>Not Logged in, return to login</>
            }
            <Footer link="analytics"/>
        </>
}
const Content = styled.div`
  background-color:white;
  height: calc(100vh - 192px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width:100%;
  border-radius:5px;`;