import React,{useRef, useLayoutEffect, useState, useContext, useEffect} from "react";
import Footer from "../components/footer/Footer";
import Cookies from "js-cookie";
import Header from "../components/header/Header";
import TronWeb from "../tronweb/src/index.js";
import styled from "styled-components";
import { WalletContext,WalletMarginContext } from "../providers/providers.js";
import Axios from "axios";
import moment from 'moment';
import { decrypt,encrypt } from "../components/main/Main.js";
import { toast,ToastContainer } from "react-toastify";

export default function Transfer(props){
    const [content,setContent] = useState(false);
    const [privateKey, setPrivateKey] = useState("");
    const [wallet,setWallet] = useState("");
    const [createWallet,setCreateWallet] = useState(true);
    const [loading,setLoading] = useState(false);
    useLayoutEffect(()=>{
        if(typeof Cookies.get("session") === "undefined" ){
            setContent(false);
        }else{
            setContent(true);
        }
    },[]);

    useEffect(()=>{
        checkWallet();
    },[]);

    const checkWallet = async ()=>{
        let session = Cookies.get("session");
        let data = await decrypt(session);
        let params = new FormData();
        let username = data.user.email;
        params.append("username", username);
        Axios.post("https://rubywallet.aylemrm.com/server/checkWallet.php",
            params
        )
        .then(async (resp)=>{
            if(resp.data.status==="Create"){
                setCreateWallet(true);
                navigate("/newAccount");
            }else if(resp.data.status==="success"){
                const user = { email: username, name: "JohnDoe", address:JSON.parse(resp.data.address) };
                // Create the session
                const expires = new Date(Date.now() + 24*60*60 * 1000 - new Date().getTimezoneOffset()*60*1000);
                const session = await encrypt({ user, expires });
                console.log(resp.data)
                // Save the session in a cookie
                Cookies.set("session", session, { expires, httpOnly: false, secure: true, path: '/', sameSite: 'none' });
                let address = JSON.parse(resp.data.address);
                //setContext(address.address.base58);
                localStorage.setItem("wallet",address.address.base58);
                setWallet(address.address.base58);
                setPrivateKey(address.privateKey)
                setCreateWallet(false);
            }
        }).catch((e)=>{
            console.log(e);
        })
    }

    //const privateKey = "694FD27F32A1BDE23526CB0CA9994572B4FB74FD21E5DE2EF5F53D636869FE44";
    const [balance,setBalance] = useState([
        {name:"TRX",value:"0",contract:"",sun:1000000,price:0},
        {name:"JST",value:"0",contract:"TF17BgPaZYbz8oxbjhriubPDsA7ArKoLX3",sun:Math.pow(10,15)*1000,price:0},
        {name:"USDT",value:"0",contract:"TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf",sun:1,price:0}]);
    const HttpProvider = TronWeb.providers.HttpProvider;
    const fullNode = new HttpProvider('https://nile.trongrid.io');
    const solidityNode = new HttpProvider('https://nile.trongrid.io');
    const eventServer = new HttpProvider('https://nile.trongrid.io');
    

    const inputValue = useRef(null);
    const inputWallet = useRef(null);
    const inputCrypto = useRef(null);
    const tabTransfer = useRef(null);
    const tabReceive = useRef(null);
    const tabMenuTransfer = useRef(null);
    const tabMenuReceive = useRef(null);
    const [checkUrl,setCheckUrl] = useState("");
    const [copy,setCopy] = useState(false);
    
    useEffect(()=>{
        setWallet(localStorage.getItem("wallet"));
    },[])
    let walletMargin = useContext(WalletMarginContext); 
    const [totalTransaction,setTotalTransaction] = useState(0);
    const [totalHistorical,setTotalHistorical] = useState(0);
    const [historyTransactions,setHistoryTransactions] = useState([]);
    const openTab=(e,tab)=>{
        tabTransfer.current.style.backgroundColor = "#ccc";
        tabReceive.current.style.backgroundColor = "#ccc";
        tabMenuTransfer.current.style.display = "none";
        tabMenuReceive.current.style.display = "none";
        switch(tab){
            case "Transfer":
                tabTransfer.current.style.backgroundColor = "#ff7e7e";
                tabMenuTransfer.current.style.display = "block";
                break;
            case "Receive":
                tabReceive.current.style.backgroundColor = "#ff7e7e";
                tabMenuReceive.current.style.display = "block";
                getEvents();
                break;
        }
      }
    const transfer = async ()=>{
        setLoading(true);
        const tronWeb = new TronWeb(fullNode,solidityNode,eventServer,privateKey);
        let amount = inputValue.current?.value;
        let costOfTransaction = parseFloat(amount)*3/1000;
        let walletTo = inputWallet.current?.value;
        let contractSelected = inputCrypto.current?.value;
        toast.loading("Loading, please wait");
        if(parseFloat(amount)*1===0){
            return alert("Value can't be 0");
        }
        if(contractSelected===""){//send trx
            const unsignedTxn = await tronWeb.transactionBuilder.sendTrx(walletTo, amount*1000000, wallet);
            const signedTxn = await tronWeb.trx.sign(unsignedTxn, privateKey);
            const receipt = await tronWeb.trx.sendRawTransaction(signedTxn);
            const transactionMargin = await tronWeb.transactionBuilder.sendTrx(walletMargin, costOfTransaction*1000000, wallet);
            setTotalTransaction(amount+costOfTransaction);
            setCheckUrl(`https://nile.tronscan.org/#/transaction/${signedTxn.txID}`);
            inputWallet.current.value = "";
            inputValue.current.value = 0;
            toast.success("Success transfer");
            setLoading(false);
        }else{
            const {
                abi
            } = await tronWeb.trx.getContract(contractSelected);
        
            const contract = tronWeb.contract(abi.entrys, contractSelected);
            let txID = await contract.transfer(walletTo, amount).send();
            let txID2 = await contract.transfer(walletMargin, costOfTransaction).send();
            setTotalTransaction(amount+costOfTransaction);
            setCheckUrl(`https://nile.tronscan.org/#/transaction/${txID}`);
            let result = await tronWeb.trx.getTransaction(txID);
            inputWallet.current.value = "";
            inputValue.current.value = 0;
            toast.success("Success transfer");
            setLoading(false);
        }
    }

    /**get token of wallet and price
     * https://nileapi.tronscan.org/api/account/token_asset_overview?address=TWcyY8MicY63E1Qyc5NNiSWRv6n3JzRycw
     * 
     * real price at time
     * https://nileapi.tronscan.org/api/token/price?token=trx
     * https://nileapi.tronscan.org/api/token/price?token=jst
     * https://nileapi.tronscan.org/api/token/price?token=usdt
     * 
     * 
     * status of blockchain
     * https://nileapi.tronscan.org/api/system/status
     */



    const getEvents = () =>{
        //trx tronWeb.getEventResult(contractAddress, options);
        Axios.get(`https://nileapi.tronscan.org/api/transaction?sort=-timestamp&count=true&limit=20&start=0&address=${wallet}`)
        .then((resp)=>{
            console.log(resp.data.data);
            setTotalHistorical(resp.data.total);
            setHistoryTransactions(resp.data.data);
        }).catch((e)=>{
            console.log("error while getting historical transaction ", e );
        })
    }
    const copyWallet = ()=>{
        navigator.clipboard.writeText(wallet)
        setCopy(true);
        setTimeout(()=>setCopy(false),5000);
    }
    const date =(date)=>{
        return moment(new Date(date)).fromNow();
    }
    return (
        <>
        {content ?
            <>
                <Header/>
                <ToastContainer/>
                <Main>
                    <TabContent>
                        <Tab ref={tabTransfer} onClick={(e)=>openTab(e,'Transfer')} className="TabButton" style={{backgroundColor:"#ff7e7e"}}>Transfer</Tab>
                        <Tab ref={tabReceive} onClick={(e)=>openTab(e,'Receive')} className="TabButton" style={{backgroundColor:"#ccc"}}>Receive</Tab>
                    </TabContent>
                    <TabMenu ref={tabMenuTransfer} id="Transfer" className="Tab" >
                        <div className="walletInfo" style={{textAlign:"center",padding:0,color:"white",display:"flex",flexDirection:"column",
                            alignItems:"center",gap:10,justifyContent:"center",textSizeAdjust:"auto",fontSize:"1rem"}}>
                            <div style={{fontWeight:"bold"}}>Wallet From</div>
                            <div>{wallet} {copy?
                            <img src="/images/check.png" alt="copy" width={20} height={20} />
                            :
                            <img src="/images/copy.png" alt="copy" width={20} height={20} 
                                style={{cursor:"pointer"}}
                                onClick={copyWallet}/>
                            }</div>
                        </div>
                        <div style={{textAlign:"center",paddingBottom:5,display:"flex",flexDirection:"row",
                            justifyContent:"center",alignItems:"center"
                        }}>
                            <div style={{color:"white",width:"10vw",minWidth:100,fontWeight:"bold"}}>Crypto</div>
                            <div>
                                <select ref={inputCrypto} style={{width:"calc(45vw + 4px)",height:47,cursor:"pointer",
                                    backgroundColor:"white",border:"transparent",borderRadius:5}}>
                                {balance.map((item,index)=>{
                                    return <option key={`option_${index}`} value={item.contract}>{item.name}</option>
                                })}
                                </select>
                            </div>
                        </div>
                        <div style={{textAlign:"center",paddingBottom:5,display:"flex",flexDirection:"row",
                            justifyContent:"center",alignItems:"center"}}>
                            <div style={{color:"white",width:"10vw",minWidth:100,fontWeight:"bold"}}>Wallet To</div>
                            <div><input ref={inputWallet} style={{width:"45vw",height:45,backgroundColor:"white",border:"transparent",borderRadius:5}} type="text" placeholder="wallet to send of type T..."/></div>
                        </div>
                        <div style={{textAlign:"center",paddingBottom:5,display:"flex",flexDirection:"row",
                            justifyContent:"center",alignItems:"center"}}>
                            <div style={{color:"white",width:"10vw",minWidth:100,fontWeight:"bold"}}>Amount</div>
                            <div><input ref={inputValue} style={{width:"45vw",height:45,backgroundColor:"white",border:"transparent",borderRadius:5}} type="number" defaultValue={0} min={1}/></div>
                        </div>
                        <div style={{textAlign:"center",paddingBottom:5,display:"flex",flexDirection:"row",
                            justifyContent:"center",alignItems:"center"}}>
                            <div style={{color:"white",width:"10vw",minWidth:100,fontWeight:"bold"}}>Total Transfer</div>
                            <div><input style={{width:"45vw",height:45,backgroundColor:"white",border:"transparent",borderRadius:5}} type="text" readOnly defaultValue={totalTransaction}/></div>
                        </div>
                        <div style={{textAlign:"center"}}>
                        <Button onClick={loading?()=>{}:transfer}>{loading?"Loading...":"Send"}</Button>
                        </div>
                        {checkUrl!=="" &&
                            <div><a href={checkUrl} target="_blank">{"View transaction"}</a></div>
                        }
                    </TabMenu>
                    <TabMenu ref={tabMenuReceive} id="Receive" className="Tab" style={{display:"none"}}>
                        <div style={{backgroundColor:"white",margin:10,borderRadius:5,paddingBottom:"1%"}}>
                            <h2>Transactions</h2>
                            <p>A total of {totalHistorical} transaction(s).</p>
                            <Table>
                                <thead>
                                    <tr style={{backgroundColor:"#f6f7fb"}}>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>Txn Hash</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>Block</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>Age</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>From</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>To</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>Token</Th>
                                        <Th style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>Result</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                {historyTransactions.length>0 ? historyTransactions.map((item,index)=>{
                                    return <Tr key={index}>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}} title={item.hash}>{item.hash.substr(0,9)}...{item.hash.substr(-5)}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>{item.block}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>{date(item.timestamp)}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}} title={item.contractData.owner_address}>{item.contractData.owner_address.substr(0,18)}...{item.contractData.owner_address.substr(-7)}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}} title={item.contractData.to_address}>{item.contractData.to_address.substr(0,18)}...{item.contractData.to_address.substr(-7)}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>{item.amount/Math.pow(10,item.tokenInfo.tokenDecimal)} {item.tokenInfo.tokenAbbr.toUpperCase()}</Td>
                                        <Td style={{fontSize:window.screen.width<400?"0.6rem":"1rem"}}>{item.result}</Td>
                                    </Tr>
                                
                                }):<Tr><Td colSpan={7}>No Transactions</Td></Tr>
                                }
                                </tbody>
                            </Table>
                            {/**
                             * get transfer from especific wallet and token
                             * https://api.trongrid.io/v1/accounts/{wallet-address}/transactions/{standard}?&contract_address={contract-address}
                             * 
                             * Replace {wallet-address} with your desired tron wallet address.

                                Replace {standard} with the standard of token that you want e.g. trc20.

                                Replace {contract_address} with the address of the contract of your target token e.g. TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t (USD Tether).

                                
                            */}
                        </div>
                    </TabMenu>
                </Main>
            </>
            :<>Not Logged in, return to login</>
            }
            <Footer link="transfer" /></>
    )
}
const Main = styled.div`
  background: linear-gradient(326deg, rgb(250, 213, 239) 0%, rgb(252, 15, 22) 35%, rgb(137, 2, 10) 80%, rgb(253, 253, 253) 99%);
  height: calc(100vh - 224px);`;
const TabContent = styled.div`
  color: #fff !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;`;
const Tab = styled.button`
  color: #000 !important;
  padding: 8px 16px;
  width: 50%;
  border: none;
  display: block;
  outline: 0;
  cursor: pointer;
  font-weight: bold;
  `;
const TabMenu = styled.div`
display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 50px);
  position: relative;
  gap: 20px;`
const Button = styled.button`
width: 240px;
  height: 45px;
  background-color: white;
  border: transparent;
  border-radius: 10px;
  font-weight: bold;
  color: red;
  font-size: 1.2rem;
  cursor:pointer;
`;
const Table = styled.table`
    width: 94%;
    margin: 0 3%;
    border-collapse: collapse;
`
const Th = styled.th`
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 0.7rem;
`;
const Td = styled.td`
    border: 1px solid #ddd;
    padding: 8px;
    word-break: break-all;
    font-size: 0.4rem;
`;
const Tr = styled.tr`
    background-color: transparent;
    :nth-child(even){background-color: #f2f2f2;}
    :hover {background-color: #ddd;}
`;