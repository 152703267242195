import React,{useLayoutEffect,useState,useRef} from "react";
import Footer from "../components/footer/Footer";
import Cookies from "js-cookie";
import Header from "../components/header/Header";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../components/main/Login.css";
import Axios from "axios";
import { isEmpty } from "validator";


export default function Configuration(props){
    const [content,setContent] = useState(false);
    const [loading,setIsLoading] = useState(false);
    const oldPassword = useRef();
    const newPassword = useRef();
    const reenterPassword = useRef();
    useLayoutEffect(()=>{
        if(typeof Cookies.get("session") === "undefined" ){
            setContent(false);
        }else{
            setContent(true);
        }
    },[]);

    const updatePassword = () =>{
        setIsLoading(true);
        let oPassword = oldPassword.current.value;
        let nPassword = newPassword.current.value;
        let rPassword = reenterPassword.current.value;
        let email = localStorage.getItem("email");
        if(isEmpty(oPassword) || isEmpty(nPassword) || isEmpty(rPassword) ){
            setIsLoading(false);
            return toast.error("The password cannot be empty");
        }
        if(nPassword===oPassword){
            setIsLoading(false);
            return toast.error("The new password cannot be the same as the previous one");
        }
        if(nPassword!==rPassword){
            setIsLoading(false);
            return toast.error("New Passwords doesn't match");
        }
        let data = {"email":email,"oPassword":oPassword,"password":nPassword};
        Axios.post("https://rubywallet.aylemrm.com/server/updatePassword.php",
            {data:data}
        ).then((resp)=>{
            if(resp.data.status==="success"){
                oldPassword.current.value = "";
                newPassword.current.value = "";
                reenterPassword.current.value = "";
                toast.success("Password updated");
            }
            if(resp.data.status==="error"){
                toast.error("Password can't be changed, old password not match");
            }
            setIsLoading(false);
        }).catch((e)=>{
            setIsLoading(false);
            console.log("Error axios 44 ", e);
        })
    }
    return (
        <>{content ?
            <>
                <Header/>
                <ToastContainer/>
                <Content style={{height:"calc(100vh - 210px)",display:"flex",
                            flexDirection:"column",justifyContent:"center",alignItems:"center"}} 
                            className="main">
                    <div className="login">
                        <div style={{fontWeight:"bold",color:"white"}}>Change user password</div>
                        <div style={{display:window.screen.width<=400?"block":"flex",textAlign:"center"}} className="case">
                            <div>
                                <img alt="user" src="/images/password.png" width={25} height={25}/>
                                <input ref={oldPassword} type="password" required style={{height: 25}} 
                                    placeholder="Enter your old password" className="caseinput"/></div>
                        </div>
                        <div style={{display:window.screen.width<=400?"block":"flex",textAlign:"center"}} className="case">
                            <div>
                                <img alt="user" src="/images/password.png" width={25} height={25}/>
                                <input ref={newPassword} type="password" required style={{height: 25}} 
                                    placeholder="Enter your new password" className="caseinput"/></div>
                        </div>
                        <div style={{display:window.screen.width<=400?"block":"flex",textAlign:"center"}} className="case">
                            <div>
                                <img alt="user" src="/images/password.png" width={25} height={25}/>
                                <input ref={reenterPassword} type="password" required style={{height: 25}} 
                                    placeholder="Reenter your new password" className="caseinput"/></div>
                        </div>
                        <div><input type="button" className="caseinput" value={loading?"Loading...":"Update Password"} onClick={loading?()=>{}:updatePassword}/></div>
                    </div>
                </Content>
            </>
            :<>Not Logged in, return to login</>
            }
            <Footer link="configuration"/>
        </>
    )
}
const Content = styled.div`
  background-color:white;
  height: calc(100vh - 192px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width:100%;
  border-radius:5px;`;